<template>
    <div>
        <v-container>
            <v-row>
                <v-col cols="2">
                    <v-avatar
                        v-if="selectedImage || editedItem.profile_photo"
                        class="profile"
                        color="grey"
                        size="164"
                        tile
                    >
                        <v-img :src="selectedImage?selectedImage:editedItem.profile_photo"></v-img>
                    </v-avatar>
                </v-col>
                <v-col class="d-flex align-end" >
                    <v-file-input @change="getImage" v-model="editedItem.profile_photo"   label="choose photo" outlined dense small></v-file-input>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                  <v-text-field v-model="editedItem.name" outlined dense label="Name" :value="editedItem.name"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                  <v-text-field v-model="editedItem.user_email" outlined dense label="Email" :value="editedItem.user_email"/>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="editedItem.phone" outlined dense label="Phone" :value="editedItem.phone"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="d-flex justify-end">
                    <v-btn @click="saveProfile" color="primary" large>Save</v-btn>
                </v-col>
            </v-row>
            <v-row v-if="false">
                <v-col class="font-weight-bold">
                    Template Universal Cost
                </v-col>
            </v-row>
             <v-row v-if="false">
                <v-col>
                  <v-text-field outlined dense label="" value="150"/>
                </v-col>
            </v-row>
            <v-row v-if="false">
                <v-col class="d-flex justify-end">
                    <v-btn color="primary" large>Save</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
export default {
  name: 'Frames',
  mixins: [validationMixin],
  data: () => ({
    headers: [
      {
        text: 'Cover',
        value: 'img_url',
        sortable: false,
        align: 'start'
      },
      { text: 'Name', value: 'name' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    data: [],
    apiUrl: process.env.VUE_APP_API_URL,
    loading: true,
    dialog: false,
    dialogDelete: false,
    error: '',
    editedIndex: -1,
    selectedImage: null,
    politicals: [],
    editedItem: {
      name: '',
      profile_photo: null,
      phone: '',
      user_email: '',
    },
    defaultItem: {
      name: '',
      profile_photo: null,
      phone: '',
      user_email: '',
    },
  }),
  created() {
    this.getProfile();
  },
  methods: {
    //Frames API Calls
    getProfile() {
      this.loading = true
      this.$Axios
        .get('get-profile')
        .then((res) => {
          this.editedItem = res.data["Success"];
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
           this.loading = false
        })
    },
    getImage(){
        let formData = new FormData();
        formData.append('profile_photo', this.editedItem.profile_photo);
        this.$Axios({
        method: 'post',
        url: 'upload-profileImage',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(({data}) => {
          this.selectedImage = data.profileImageUrl
        })
        .catch((e) => {
          console.log(e) 
        });
    },
    saveProfile() {
      
      this.error = ''
      this.$v.$touch() 
      if(this.$v.$error && this.editedItem.type  == "Political"){
        return
      }
      
      let data = {}
      if(this.editedItem.name) data.name = this.editedItem.name
      if(this.editedItem.profile_photo && this.selectedImage) data.profile_photo = this.selectedImage
      if(this.editedItem.user_email)data.user_email = this.editedItem.user_email
     if(this.editedItem.phone) data.phone = this.editedItem.phone
    
      this.$Axios
        .post('update-profile', data)
        .then(() => {
          this.getProfile();
        })
        .catch((e) => {
          console.log(e) 
        });
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Frame' : 'Edit Frame';
    },
    formIcon() {
      return this.editedIndex === -1 ? 'mdi-plus' : 'mdi-edit';
    },
    filtredItems() {
      if (this.filter) return this.desserts.filter(item => item.name.includes(this.filter) || item.license.includes(this.filter) || item.email.includes(this.filter) || item.db.includes(this.filter) )
      else return this.desserts
    },
    //// Vuelidate Errors
    nameErrors () {
        const errors = []
        if (!this.$v.editedItem.name.$dirty) return errors
        !this.$v.editedItem.name.required && errors.push('Name is required !')
        return errors
    },
    phoneErrors () {
        const errors = []
        if (!this.$v.editedItem.phone.$dirty) return errors
        !this.$v.editedItem.phone.required && errors.push('Phone is required !')
        return errors
      },
    user_emailErrors () {
        const errors = []
        if (!this.$v.editedItem.user_email.$dirty) return errors
        !this.$v.editedItem.user_email.required && errors.push('Email is required')
        return errors
    },
  },
  validations: {
    editedItem: {
      name: {
        required
      },
      phone: {
        required
      },
      user_email: {
        required
      },
    },
  },
};
</script>
